<template>
  <v-container class="pa-5">
    <v-card v-if="reviews.length" class="pb-10">
      <v-card-title class="pt-5 pl-10"> Ratting and Review </v-card-title>
      <v-row class="ml-7" style="width: 96%; max-height: 600px">
        <v-col cols="3" class="pt-0">
          <v-card
            style="background: #f5f5f5; height: 100%; overflow: auto"
            elevation="0"
          >
            <v-card-actions class="ml-2 mb-2">
              <v-list three-line color="#f5f5f5" width="100%">
                <template v-for="(item, index) in reviews">
                  <v-divider
                    v-if="item.divider"
                    :key="index"
                    :inset="item.inset"
                  ></v-divider>

                  <v-list-item
                    v-else
                    :key="item.name"
                    @click="openchat(item)"
                    round
                  >
                    <v-avatar v-if="item.name" color="indigo">
                      <span class="white--text">{{
                        item.name.slice(0, 2)
                      }}</span>
                    </v-avatar>
                    <v-avatar v-else color="indigo">
                      <span class="white--text">Un</span>
                    </v-avatar>

                    <v-list-item-content class="pl-4 mt-3">
                      <v-list-item-title v-if="item.name">
                        User Name: {{ item.name }}
                      </v-list-item-title>
                      <v-list-item-title v-else>Unknown</v-list-item-title>
                      <v-list-item-subtitle>
                        User ID: {{ item.user_id }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="9" class="pt-0">
          <v-card style="background: #f5f5f5" elevation="0" min-height="100%">
            <v-row class="pa-5">
              <v-col> Product ID: {{ showItem.product_id }} </v-col>
              <v-col> Product Title: {{ pro_tle.product_title }} </v-col>
              <v-col> User ID: {{ showItem.user_id }} </v-col>
              <v-col cols="2">
                <v-btn color="red" @click="dialog = true" elevation="0"
                  >delete</v-btn
                >
              </v-col>
            </v-row>

            <v-row class="pa-5">
              <v-card color="#f5f5f5"  elevation="0">
                <v-card-text>
                  <v-row class="ml-0">
                    <v-col>
                      <p class="mr-2">Rating: {{ showItem.rating }}</p>
                      <v-rating
                        v-model="showItem.rating"
                        background-color="white"
                        color="yellow accent-4"
                        dense
                        half-increments
                        hover
                        size="18"
                        readonly
                      ></v-rating>
                    </v-col>
                  </v-row>
                  <v-row class="ml-0">
                    <v-col>
                      <p>{{ showItem.content }}</p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-card v-else min-height="70vh" class="d-flex align-center justify-center">
      <p class="text-h4">There is no ratings and reviews to show</p>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline font-weight-medium">
          Are you sure ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            cancel
          </v-btn>
          <v-btn color="red" small @click="deleteItem" elevation="0">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    dialog: false,
    dialog2: false,
    reply: "",
    replies: [],
    reply: null,
    showItem: {},
    checkexist: false,

    reviews: [],
    newarry: [],
    spticket: [],
    pro_tle: [],

    deleteItemindex: -1,

    editedIndex: -1,
    editedItem: {
      reply: "",
    },
    defaultItem: {
      reply: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Ticket" : "Edit Ticket";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  watch: {
    replies() {
      setTimeout(() => {
        this.$refs.chat.$el.scrollTop = this.$refs.chat.$el.scrollHeight;
      }, 0);
    },
  },
  methods: {
    initialize() {
      axios
        .get(`product/reviews_product/${this.$route.query.id}/`)
        .then((response) => {
          this.product_id = this.$route.query.id;
          this.reviews = response.data.data;
          this.showItem = this.reviews[0];
         
          this.pro_tle = response.data;
        });
    },

    confirm() {
      this.deleteItemindex = item.id;
      this.dialog = true;
    },

    deleteItem() {
      axios
        .post(`product/delete_review/${this.showItem.id}/`)
        .then((response) => {
          this.reviews = this.reviews.filter(
            (item) => this.showItem.id !== item.id
          );
          Object.assign(this.showItem, this.reviews[0]);
        });

        this.dialog = false;
    },

    openchat(item) {

      this.showItem = item;
  
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
